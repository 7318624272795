import React from "react"
import { Link, graphql } from "gatsby"
import marked from "marked"
import Layout from "@components/layout/layout"
import SeoComponent from "@components/seo"
import PageHeading from "@components/pageHeading"
// import HeadingBG from "@images/heading-bg.jpg"
// import pageSLide from "@images/commercial.jpg"

// swiper
import Swiper from "react-id-swiper"
import "swiper/dist/css/swiper.min.css"

import { LazyLoadComponent } from "react-lazy-load-image-component"

const TemplatePage = ({ data, location }) => {
  const { page } = data

  return (
    <Layout location={location}>
      <SeoComponent
        title={page.metaTitle ? page.metaTitle : page.title}
        description={page.metaDesc}
        keywords={page.metaKeywords.join()}
      />
      <PageHeading
        bgImage={
          page?.heroBanner?.asset?.gatsbyImageData?.images?.fallback?.src
        }
      >
        <div className="container">
          <div className="row justify-content-center text-center">
            <div className="col-lg-10">
              <h1 className="display-4 text-white">{page.title}</h1>
            </div>
          </div>
        </div>
      </PageHeading>
      <div className="py-10 dynamic-page-content-wrapper">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div
                dangerouslySetInnerHTML={{
                  __html: marked(page.body || ""),
                }}
              />
              <div className="row mt-5 ">
                {page.cardImage.map((item) => (
                  <div
                    className={`col-md-${
                      page.cardCol === 2 ? 6 : page.cardCol === 3 ? 4 : 6
                    } mb-5`}
                  >
                    <div className="image-link-wrapper">
                      <Link to={item.url}>
                        <div className="image image-wrapper-rectangle mb-2">
                          <div
                            className="image-bg"
                            style={{
                              backgroundSize: `cover`,
                              backgroundImage: `url(${item?.imageHead?.asset?.url})`,
                            }}
                          ></div>
                        </div>
                        <span className="d-block h5 image-link-text text-left">
                          {item.name}
                        </span>
                      </Link>
                    </div>
                    <p>{item.description}</p>
                    <Link
                      className="btn btn-primary btn-sm btn-square-arrow"
                      to={item.url}
                    >
                      See More
                    </Link>
                  </div>
                ))}
              </div>
              <div className="text-center">
                <Link className="btn btn-primary font-weight-bold" to="/quote">
                  Get a Quote
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5">
        <LazyLoadComponent>
          <div className="page-swiper-wrapper">
            <Swiper
            autoplay={{delay: "3000",}}
              speed={1000}
              slidesPerView={1}
              loop={true}
              pagination={{
                el: ".swiper-pagination",
                type: "bullets",
                clickable: true,
              }}
              navigation={{
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
              }}
              // breakpoints={{
              //   992: {
              //     autoHeight: false,
              //   },
              //   320: {
              //     autoHeight: true,
              //   },
              // }}
            >
              {page.slideImages.map((slide, index) => (
                <div
                  className="page-slide"
                  key={index}
                  style={{
                    background: `url(${slide?.asset?.url})`,
                  }}
                ></div>
              ))}
            </Swiper>
          </div>
        </LazyLoadComponent>
      </div>
    </Layout>
  )
}

export default TemplatePage

export const pageQuery = graphql`
  query PageTemplateQuery($id: String!) {
    page: sanityPage(id: { eq: $id }) {
      id
      body
      title
      metaTitle
      metaDesc
      metaKeywords
      cardCol
      cardImage {
        description
        name
        url
        imageHead {
          asset {
            url
          }
        }
      }
      heroBanner {
        asset {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR)
        }
      }
      slideImages {
        alt
        asset {
          url
        }
      }
    }
  }
`
